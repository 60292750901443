import type { FC } from 'react';
import React, { useContext, Fragment } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { N200 } from '@atlaskit/theme/colors';
import QueuesIcon from '@atlaskit/icon/glyph/queues';
import LightbulbFilledIcon from '@atlaskit/icon/glyph/lightbulb-filled';

import { PageCardContext } from '../PageCardContext';

import PageTreeIcon from './pageTreeIcon.svg';
import { LastAction } from './LastAction';

const i18n = defineMessages({
	BEHAVIORAL: {
		id: 'page-card.rec.strategy.behavioral',
		defaultMessage: 'Often read together',
		description:
			'Page card recommendation is based on user behavior of when pages are viewed together in a session',
	},
	CONTENT_TO_CONTENT: {
		id: 'page-card.rec.strategy.content-to-content',
		defaultMessage: 'More like this',
		description: 'Page card recommendation is based on similarity of content between pages',
	},
	STRUCTURAL: {
		id: 'page-card.rec.strategy.structural',
		defaultMessage: 'Organized together',
		description:
			'Page card recommendation is based on page hierarchy (parent, child, sibling pages)',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StrategyContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	fontSize: '12px',
	lineHeight: '16px',
	fontWeight: 500,
	color: token('color.text.subtle', N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StrategyText = styled.span({
	marginLeft: token('space.100', '8px'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const Icons = {
	BEHAVIORAL: <QueuesIcon size="small" primaryColor={token('color.icon.subtle', N200)} label="" />,
	CONTENT_TO_CONTENT: (
		<LightbulbFilledIcon size="small" primaryColor={token('color.icon.subtle', N200)} label="" />
	),
	STRUCTURAL: (
		<img
			src={PageTreeIcon}
			alt="page-tree-icon"
			color={token('color.icon.subtle', N200)}
			width="16"
			height="16"
		/>
	),
};

//Removed from end of page recommendations for now and may be added back later
export const RecommendationStrategy: FC = () => {
	const { recommendationStrategy } = useContext(PageCardContext);
	const Icon =
		recommendationStrategy && recommendationStrategy in i18n && Icons[recommendationStrategy];

	return recommendationStrategy && recommendationStrategy in i18n ? (
		<StrategyContainer>
			<Fragment>
				{Icon}
				<StrategyText>
					<FormattedMessage {...i18n[recommendationStrategy]} />
				</StrategyText>
			</Fragment>
		</StrategyContainer>
	) : (
		<LastAction />
	);
};

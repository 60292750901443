import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import {
	ContentIcon,
	Subtitle,
	Title,
	RecommendationStrategy,
	PageLink,
	PageCardContainer,
	Card,
} from '../Items';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledCard = styled(Card)({
	'flex-basis': '100%',
	flex: '1',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GridCard = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: 'calc(100% - 32px)',
	height: 'calc(100% - 28px)',
	maxHeight: '260px',
	padding: `${token('space.150', '12px')} ${token('space.200', '16px')} ${token(
		'space.200',
		'16px',
	)} ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.div({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'flex-start',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div({
	display: 'flex',
	marginTop: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitlesContainer = styled.div({
	overflow: 'hidden',
	overflowWrap: 'break-word',
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleContainer = styled.div({
	display: '-webkit-box',
	WebkitLineClamp: '3',
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
	lineHeight: '16px',
	fontSize: '12px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtitleContainer = styled.div({
	display: 'flex',
	lineHeight: '14px',
	fontSize: '11px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterContainer = styled.div({
	flex: '1 0 auto',
	display: 'flex',
	alignItems: 'flex-end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Footer = styled.div({
	width: '100%',
	marginTop: token('space.200', '16px'),
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

export const EndOfPageView: FC = () => (
	// ⚠️ WARNING: Do you need to add conditional logic? Please refer to README#Views ⚠️
	<PageCardContainer StyledCard={StyledCard}>
		<PageLink />
		<GridCard data-testid="page-card-end-of-page-view">
			<Header>
				<IconContainer>
					<ContentIcon />
				</IconContainer>
				<TitlesContainer>
					<TitleContainer>
						<Title />
					</TitleContainer>
					<SubtitleContainer>
						<Subtitle />
					</SubtitleContainer>
				</TitlesContainer>
			</Header>
			<FooterContainer>
				<Footer>
					<RecommendationStrategy />
				</Footer>
			</FooterContainer>
		</GridCard>
	</PageCardContainer>
);
